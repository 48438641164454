import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './styles.scss'

function CarouselThumbnails({ images, selectedImage, onHoverThumbnail }) {
  return (
    <div className="carousel-thumbnails">
      {images.map(image => (
        <div
          key={`image-${image.url}`}
          className={classnames('carousel-thumbnail', {
            'carousel-thumbnail--selected': image.url === selectedImage.url,
          })}
          onMouseEnter={() => onHoverThumbnail(image)}
        >
          <img src={`${image.url}?q=10`} alt={image.description} />
        </div>
      ))}
    </div>
  )
}

CarouselThumbnails.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  onHoverThumbnail: PropTypes.func.isRequired,
}

export default memo(CarouselThumbnails)
