import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

function CarouselZoom({
  shouldDisplay,
  selectedImage,
  width,
  height,
  backgroundSize,
  backgroundPosition,
}) {
  const zoomStyle = {
    backgroundImage: `url(${selectedImage.url})`,
    backgroundSize,
    backgroundPosition,
    width,
    height,
  }

  if (shouldDisplay) {
    zoomStyle.opacity = '1'
  }

  return <div className="carousel-zoom" style={zoomStyle} />
}

CarouselZoom.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  selectedImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  backgroundSize: PropTypes.string.isRequired,
  backgroundPosition: PropTypes.string.isRequired,
}

export default memo(CarouselZoom)
