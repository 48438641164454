import { defineMessages } from 'gatsby-plugin-intl'

const key = 'containers.garment'

const messages = defineMessages({
  createdBy: {
    id: `${key}.createdBy`,
    defaultMessage: `Created by`,
  },
})

export default messages
