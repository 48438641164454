export function formatCreatorsNames(creators) {
  const creatorsNames = creators.map(
    creator => `${creator.firstName} ${creator.lastName}`,
  )

  if (creatorsNames.length === 1) {
    return creatorsNames[0]
  }

  return creatorsNames.reduce((namesJoined, name, index) => {
    if (index === 0) {
      return name
    } else if (index === creatorsNames.length - 1) {
      return `${namesJoined} & ${name}`
    }

    return `${namesJoined}, ${name}`
  }, '')
}
