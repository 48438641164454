import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { injectIntl, intlShape } from 'gatsby-plugin-intl'

import messages from './messages'

import './styles.scss'

function GoBackButton({ className, linkTo, intl }) {
  return (
    <Link className={classnames('go-back-button', className)} to={linkTo}>
      {intl.formatMessage(messages.back)}
    </Link>
  )
}

GoBackButton.propTypes = {
  className: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
}
GoBackButton.defaultProps = {
  className: '',
}

export default injectIntl(GoBackButton)
