import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CarouselThumbnails from './CarouselThumbnails'
import CarouselMainView from './CarouselMainView'
import CarouselZoom from './CarouselZoom'

import { getPageHeight } from '../../utils/dimensions'

import './styles.scss'

const DEFAULT_ZOOM_INDICATOR_WIDTH_IN_PIXELS = 100
const DEFAULT_ZOOM_INDICATOR_HEIGHT_IN_PIXELS = 100
const ZOOM_SIDES_MARGIN_IN_PIXELS = 24
const ZOOM_BOTTOM_MARGIN = 8

function Carousel({ images }) {
  const [selectedImage, setSelectedImage] = useState(images[0])
  const [isHoveringMainView, setIsHoveringMainView] = useState(false)
  const [zoomIndicatorWidthInPixels, setZoomIndicatorWidthInPixels] = useState(
    DEFAULT_ZOOM_INDICATOR_WIDTH_IN_PIXELS,
  )
  const [
    zoomIndicatorHeightInPixels,
    setZoomIndicatorHeightInPixels,
  ] = useState(DEFAULT_ZOOM_INDICATOR_HEIGHT_IN_PIXELS)
  const [zoomWidth, setZoomWidth] = useState('inherit')
  const [zoomHeight, setZoomHeight] = useState('inherit')
  const [zoomBgPosition, setZoomBgPosition] = useState('')
  const [zoomBgSize, setZoomBgSize] = useState('')
  const [zoomRatio, setZoomRatio] = useState(1)

  useEffect(() => {
    updateZoomBgSize(zoomRatio)
  }, [zoomRatio])

  function updateZoomBgSize(updatedZoomRatio) {
    const { width, height } = document
      .querySelector('.carousel-main-view img')
      .getBoundingClientRect()
    const imageWidth = Math.round(width * 100) / 100
    const imageHeight = Math.round(height * 100) / 100
    const zoomBgWidth = imageWidth * updatedZoomRatio
    const zoomBgHeight = imageHeight * updatedZoomRatio

    setZoomBgSize(`${zoomBgWidth}px ${zoomBgHeight}px`)
  }

  function updateZoomDimensions() {
    const newZoomWidth = getNewZoomWidth()
    const newZoomHeight = getNewZoomHeight()
    const newZoomIndicatorWidth = getNewZoomIndicatorWidth(newZoomWidth)
    const newZoomRatio = newZoomWidth / newZoomIndicatorWidth
    const newZoomIndicatorHeight = newZoomHeight / newZoomRatio

    setZoomWidth(`${newZoomWidth}px`)
    setZoomHeight(`${newZoomHeight}px`)
    setZoomIndicatorWidthInPixels(newZoomIndicatorWidth)
    setZoomIndicatorHeightInPixels(newZoomIndicatorHeight)
    setZoomRatio(newZoomRatio)
    updateZoomBgSize(newZoomRatio)
  }

  function getNewZoomWidth() {
    const contentRightBorderPosX = document
      .querySelector('.garment-content')
      .getBoundingClientRect().right
    const carouselMainViewRightBorderPosX = document
      .querySelector('.carousel-main-view__image')
      .getBoundingClientRect().right

    return (
      contentRightBorderPosX -
      carouselMainViewRightBorderPosX -
      ZOOM_SIDES_MARGIN_IN_PIXELS
    )
  }

  function getNewZoomHeight() {
    const { top, bottom } = document
      .querySelector('.carousel-main-view__image')
      .getBoundingClientRect()
    const pageHeight = getPageHeight()
    let newZoomHeight = Math.min(pageHeight, bottom) - top

    if (top < 0) {
      if (bottom > pageHeight) {
        newZoomHeight = pageHeight
      } else {
        newZoomHeight = bottom
      }
    }

    return newZoomHeight - ZOOM_BOTTOM_MARGIN
  }

  function getNewZoomIndicatorWidth(newZoomWidth) {
    const { width: imageWidth } = document
      .querySelector('.carousel-main-view img')
      .getBoundingClientRect()
    let newZoomIndicatorWidth = newZoomWidth / zoomRatio
    let ratioSupp = 0

    while (newZoomIndicatorWidth > imageWidth / 2) {
      ratioSupp += 0.5
      newZoomIndicatorWidth = newZoomWidth / (zoomRatio + ratioSupp)
    }

    return newZoomIndicatorWidth
  }

  function handleHoverThumbnail(image) {
    setSelectedImage(image)
  }

  function handleEnterMainView() {
    setIsHoveringMainView(true)
    updateZoomDimensions()
  }

  function handleLeaveMainView() {
    setIsHoveringMainView(false)
    setZoomWidth('inherit')
  }

  function handleZoomIndicatorPositionChange({
    zoomIndicatorPosX,
    zoomIndicatorPosY,
  }) {
    const zoomBgPosX = -zoomIndicatorPosX * zoomRatio
    const zoomBgPosY = -zoomIndicatorPosY * zoomRatio

    setZoomBgPosition(`${zoomBgPosX}px ${zoomBgPosY}px`)
  }

  return (
    <div className="carousel">
      <CarouselThumbnails
        images={images}
        selectedImage={selectedImage}
        onHoverThumbnail={handleHoverThumbnail}
      />

      <CarouselMainView
        selectedImage={selectedImage}
        isHoveringMainView={isHoveringMainView}
        zoomIndicatorWidthInPixels={zoomIndicatorWidthInPixels}
        zoomIndicatorHeightInPixels={zoomIndicatorHeightInPixels}
        onMouseEnter={handleEnterMainView}
        onMouseLeave={handleLeaveMainView}
        onZoomIndicatorPositionChange={handleZoomIndicatorPositionChange}
      />

      <CarouselZoom
        shouldDisplay={isHoveringMainView}
        selectedImage={selectedImage}
        width={zoomWidth}
        height={zoomHeight}
        backgroundSize={zoomBgSize}
        backgroundPosition={zoomBgPosition}
      />
    </div>
  )
}

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Carousel
