import React, { useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'gatsby-plugin-intl'

import Layout from '../../components/Layout'
import Carousel from '../../components/Carousel'
import GoBackButton from '../../components/buttons/GoBackButton'

import { formatCreatorsNames } from '../../utils/garment'

import messages from './core/messages'

import './styles.scss'

function Garment({ garment, intl }) {
  const { name, image, otherImages, brand } = garment
  const allImages = useMemo(() => [image, ...otherImages || []], [image, otherImages])
  const images = useMemo(() => allImages.map(img => ({
    url: img.file.url,
    description: img.description,
  })), [allImages])
  const creatorsNamesFormatted = useMemo(
    () => brand ? formatCreatorsNames(brand.creators) : '',
    [brand],
  )

  return (
    <Layout>
      <div className="garment-container">
        <div className="garment-header">
          <GoBackButton className="garment-header__back-link" linkTo="/" />
          <h1 className="garment-title">{name}</h1>
        </div>

        <div className="garment-content">
          <div className="garment-images">
            <Carousel images={images} />
          </div>

          <div className="garment-info">
            {intl.formatMessage(messages.createdBy)} {creatorsNamesFormatted}
          </div>
        </div>
      </div>
    </Layout>
  )
}

Garment.propTypes = {
  garment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
      description: PropTypes.string.isRequired,
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }).isRequired,
    otherImages: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    ).isRequired,
    brand: PropTypes.shape({
      creators: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
}

const GarmentWithIntl = injectIntl(Garment)

export default memo(GarmentWithIntl)
