import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

function CarouselZoomIndicator({
  isHoveringMainView,
  width,
  height,
  positionX,
  positionY,
}) {
  const zoomIndicatorStyle = {
    width: `${width}px`,
    height: `${height}px`,
  }

  if (isHoveringMainView) {
    zoomIndicatorStyle.display = 'block'
  }

  return (
    <div
      className="carousel-zoom-indicator"
      style={{
        ...zoomIndicatorStyle,
        top: `${positionY}px`,
        left: `${positionX}px`,
      }}
    />
  )
}

CarouselZoomIndicator.propTypes = {
  isHoveringMainView: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  positionX: PropTypes.number.isRequired,
  positionY: PropTypes.number.isRequired,
}

export default memo(CarouselZoomIndicator)
