import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'

import CarouselZoomIndicator from '../CarouselZoomIndicator'

import './styles.scss'

function CarouselMainView({
  selectedImage,
  isHoveringMainView,
  zoomIndicatorWidthInPixels,
  zoomIndicatorHeightInPixels,
  onMouseEnter,
  onMouseLeave,
  onZoomIndicatorPositionChange,
}) {
  const [zoomIndicatorPosX, setZoomIndicatorPosX] = useState(0)
  const [zoomIndicatorPosY, setZoomIndicatorPosY] = useState(0)
  const [zoomIndicatorPosXLowLimit, setZoomIndicatorPosXLowLimit] = useState(
    zoomIndicatorWidthInPixels / 2 + 1,
  )
  const [zoomIndicatorPosYLowLimit, setZoomIndicatorPosYLowLimit] = useState(
    zoomIndicatorHeightInPixels / 2 + 1,
  )

  useEffect(() => {
    setZoomIndicatorPosXLowLimit(zoomIndicatorWidthInPixels / 2 + 1)
  }, [zoomIndicatorWidthInPixels])

  useEffect(() => {
    setZoomIndicatorPosYLowLimit(zoomIndicatorHeightInPixels / 2 + 1)
  }, [zoomIndicatorHeightInPixels])

  useEffect(() => {
    onZoomIndicatorPositionChange({ zoomIndicatorPosX, zoomIndicatorPosY })
  }, [zoomIndicatorPosX, zoomIndicatorPosY])

  function handleMouseMove(event) {
    const mainViewRect = event.currentTarget.getBoundingClientRect()
    const distanceToTop = mainViewRect.top
    const distanceToLeft = mainViewRect.left
    const mainViewWidth = mainViewRect.width
    const mainViewHeight = mainViewRect.height
    const posX = event.clientX - distanceToLeft
    const posY = event.clientY - distanceToTop

    if (posX > zoomIndicatorPosXLowLimit) {
      if (posX < mainViewWidth - zoomIndicatorPosXLowLimit) {
        setZoomIndicatorPosX(posX - zoomIndicatorPosXLowLimit)
      } else {
        setZoomIndicatorPosX(mainViewWidth - zoomIndicatorWidthInPixels - 2)
      }
    } else {
      setZoomIndicatorPosX(0)
    }

    if (posY > zoomIndicatorPosYLowLimit) {
      if (posY < mainViewHeight - zoomIndicatorPosYLowLimit) {
        setZoomIndicatorPosY(posY - zoomIndicatorPosYLowLimit)
      } else {
        setZoomIndicatorPosY(mainViewHeight - zoomIndicatorHeightInPixels - 2)
      }
    } else {
      setZoomIndicatorPosY(0)
    }
  }

  return (
    <div
      className="carousel-main-view"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <img
        className="carousel-main-view__image"
        src={`${selectedImage.url}?q=50`}
        alt={selectedImage.description}
      />

      <CarouselZoomIndicator
        width={zoomIndicatorWidthInPixels}
        height={zoomIndicatorHeightInPixels}
        positionX={zoomIndicatorPosX}
        positionY={zoomIndicatorPosY}
        isHoveringMainView={isHoveringMainView}
      />
    </div>
  )
}

CarouselMainView.propTypes = {
  selectedImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  isHoveringMainView: PropTypes.bool.isRequired,
  zoomIndicatorWidthInPixels: PropTypes.number.isRequired,
  zoomIndicatorHeightInPixels: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onZoomIndicatorPositionChange: PropTypes.func.isRequired,
}

export default memo(CarouselMainView)
