import { defineMessages } from 'gatsby-plugin-intl'

const key = 'components.buttons.goBackButton'

const messages = defineMessages({
  back: {
    id: `${key}.back`,
    defaultMessage: `Back`,
  },
})

export default messages
