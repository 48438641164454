import React from 'react'
import { graphql } from 'gatsby'

import Garment from '../containers/Garment'

export default ({ data }) => {
  const garment = data.allContentfulGarment.edges[0].node

  return <Garment garment={garment} />
}

export const query = graphql`
  query($id: String!) {
    allContentfulGarment(filter: { id: { eq: $id } }) {
      edges {
        node {
          name
          image {
            description
            file {
              url
            }
          }
          otherImages {
            description
            file {
              url
            }
          }
          brand {
            creators {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
